.loginTitle {
  @apply font-[700] text-[24px] text-[#254570] my-2;
}

.subTextLog {
  @apply text-[#CCCCCC] text-[14px] font-[700];
}
.imputTitle {
  @apply text-[#254570] text-[18px] font-[700];
}
.input {
  @apply bg-white h-[50px] outline-none p-2 rounded-md border text-[16px] w-full  text-[#064035] my-2;
}
.input2 {
  @apply bg-[#CCCCCC]/10 h-[50px] outline-none p-2 rounded-md border text-[16px] w-full  text-[#064035] my-2;
}
.input3 {
  @apply bg-[#CCCCCC]/10  outline-none p-2 rounded-md border text-[16px] w-full  text-[#064035] my-2;
}
.LogBtn {
  @apply bg-[#0D8F75] font-[700] text-white rounded-[10px] h-[50px] outline-none p-2  border text-[16px] w-full my-2;
}
.LogBtn2 {
  @apply bg-[#C90000] font-[700] text-white rounded-[10px] h-[50px] outline-none p-2  border text-[16px] w-full my-2;
}
.LogSubBtn {
  @apply bg-[#fff] font-[700] text-[#254570] rounded-[10px] h-[50px] outline-none p-2  border border-[#254570] text-[16px] w-full my-2;
}
.subtitle {
  @apply font-[700] text-[#0D8F75] text-[18px];
}

.container input:checked ~ .checkmark {
  background-color: #254570;
}
.noti {
  @apply border-b;
}
.noti:last-child {
  @apply border-none;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 3px;
  top: 1.5px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.out {
  animation: out 1000ms ease-in-out;
}

@keyframes out {
  0% {
    opacity: 0;
    bottom: -100%;
  }
  100% {
    opacity: 1;
    bottom: 0%;
  }
}
.in {
  animation: in 1000ms ease-in-out;
}

@keyframes in {
  0% {
    opacity: 1;
    bottom: 0%;
  }
  100% {
    opacity: 0;
    bottom: -100%;
  }
}

.open {
  animation: open 1000ms ease-in-out forwards;
}
@keyframes open {
  0% {
    display: none;
  }
  1% {
    display: flex;
    opacity: 0;
  }
  100% {
    opacity: 1;
    display: flex;
  }
}
.close {
  animation: close 1000ms ease-in-out forwards;
}
@keyframes close {
  0% {
    display: flex;
    opacity: 1;
  }
  99% {
    display: flex;
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.sh-2 {
  box-shadow: 6px 6px 54px 0px #0000000d;
  /* -webkit-box-shadow: 0px 5px 19px 0px rgba(0,0,0,0.3); */
  /* -moz-box-shadow: 0px 5px 19px 0px rgba(0,0,0,0.3); */
}
