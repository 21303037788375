@tailwind base;
@tailwind components;
@tailwind utilities;
.almarai-light {
  font-family: "Almarai", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.almarai-regular {
  font-family: "Almarai", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.almarai-bold {
  font-family: "Almarai", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.almarai-extrabold {
  font-family: "Almarai", sans-serif;
  font-weight: 800;
  font-style: normal;
}
.rtl {
  direction: rtl;
}

.loader {
  width: 3.5em;
  height: 4em;
  border: 3px solid transparent;
  border-top-color: #0d8f75;
  border-bottom-color: #0d8f75;
  border-radius: 50%;
  animation: spin-stretch 2s ease infinite;
}

@keyframes spin-stretch {
  50% {
    transform: rotate(360deg) scale(0.4, 0.33);
    border-width: 8px;
  }
  100% {
    transform: rotate(720deg) scale(1, 1);
    border-width: 3px;
  }
}
